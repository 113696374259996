import React from 'react'
import './portfolio.css'
import IMG1 from '../../assets/facilitamed.png'
import IMG2 from '../../assets/word-emb.png'
import IMG3 from '../../assets/rational.png'
import { FaEmber } from 'react-icons/fa'

const Portfolio = () => {
  return (
    <section id='portfolio'>
       <h5>My Work</h5>
       <h2>Portfolio</h2>

       <div className="container portfolio__container">
         <article className="portfolio__item">
           <div className="portfolio__item-image">
             <img className="image2" src={IMG1} alt=''></img>
           </div>
           <h3 className='proj_name'>Facilitamed.eV</h3>
            <div className="portfolio__item-cta">
            <a href="https://github.com/jsandoval2610/Facilitamed" className='btn boton' target={'_blank'}>Github</a>
           <a href="https://facilitamed.org" className='btn btn-primary boton' target={'_blank'}>Live Demo</a>
            </div>
         </article>

         <article className="portfolio__item">
           <div className="portfolio__item-image">
             <img className="image2" src={IMG2} alt=''></img>
           </div>
           <h3 className='proj_name'>Word Embedder</h3>
            <div className="portfolio__item-cta">
            <a href="https://github.com/jsandoval2610/WordEmbedder" className='btn boton' target={'_blank'}>Github</a>
           <a href="" className='btn btn-primary boton' target={'_blank'}>Live Demo</a>
            </div>
         </article>

         <article className="portfolio__item">
           <div className="portfolio__item-image">
             <img className="image2" src={IMG3} alt=''></img>
           </div>
           <h3 className='proj_name'>Simplepedia</h3>
            <div className="portfolio__item-cta">
            <a href="https://github.com/csci312a-f23/assignment-04-jsandoval2610" className='btn boton' target={'_blank'}>Github</a>
           <a href="" className='btn btn-primary boton' target={'_blank'}>Live Demo</a>
            </div>
         </article>
       </div>
    </section>
  )
}

export default Portfolio