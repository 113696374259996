import React from 'react'
import { useState } from 'react'
import './about.css'
import ME from '../../assets/about_me.jpg'
import {FaAward} from 'react-icons/fa'
import {FiZap} from 'react-icons/fi'
import {VscFolderLibrary} from 'react-icons/vsc'

const About = () => {
  const [showAchievements, setShowAchievements] = useState(false);
  const [showCourses, setShowCourses] = useState(false);
  const [showInterests, setShowInterests] = useState(false);


  return (
    <section id='about'>
      <h5>Get to Know</h5>
      <h2>About Me</h2>

      <div className="containet about__container">
        <div className="about__me">
            <div className="about__me-image">
              <img src={ME} alt="About image" />
            </div>

        </div>

        <div className="about__content">
            <div className="about__cards">

              <article className='about__card' onClick={() => setShowInterests(!showInterests)}>
                <FiZap className='about__icon' />
                <h5>Interests</h5>
              {showInterests && (
                <div className="projects-popup">
                  {/* Close Button */}
                  <button className="popup-close" onClick={() => setShowInterests(false)}>X</button>
                  <h1 className='list_title'>Interests</h1>
                  <ul>
                    <li>Deep Learning</li>
                    <li>Artificial Intelligence</li>
                    <li>Financial Trading</li>
                    <li>Cybersecurity</li>
                    <li>Entrepreneurship and Startups</li>
                  </ul>
                </div>
              )}
              </article>

              <article className='about__card' onClick={() => setShowCourses(!showCourses)}>
                <VscFolderLibrary className='about__icon' />
                <h5>Courses</h5>
              {showCourses && (
                <div className="projects-popup">
                  {/* Close Button */}
                  <button className="popup-close" onClick={() => setShowCourses(false)}>X</button>
                  <h1 className='list_title'>Courses</h1>
                  <ul>
                    <li>Algorithms and Complexity</li>
                    <li>Software Development</li>
                    <li>Data Structures</li>
                    <li>Multivariable Calculus</li>
                    <li>Microeconomic Theory</li>
                  </ul>
                </div>
              )}
              </article>

              <article className='about__card' onClick={() => setShowAchievements(!showAchievements)}>
                <FaAward className='about__icon' />
                <h5>Achievements</h5>
              {showAchievements && (
                <div className="projects-popup">
                  {/* Close Button */}
                  <button className="popup-close" onClick={() => setShowAchievements(false)}>X</button>
                  <h1 className='list_title'>Achievements</h1>
                  <ul>
                    <li>Dean's List (present)</li>
                    <li>Middlebury Hackathon Winner (May 2022)</li>
                    <li></li>
                  </ul>
                </div>
              )}
              </article>
            </div>
            <p>
            Hi, I'm Julian, a 20-year-old passionate individual from Guatemala.  I am currently an undergraduate at Middlebury College pursuing a double major in Computer Science and Economics. 
            Developing websites sparked my interest during my first year of college which is why I decided to create this website in order to display my skills.
            </p>

            <a href="#contact" className='btn btn-primary boton'>Let's Talk</a>
        </div>
      </div>
    </section>
  )
}

export default About